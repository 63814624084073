@import url("https://fonts.googleapis.com/css?family=Roboto+Slab|Fredoka+One|Lato:700");

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

body,
p {
  color: #444;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
}
p {
  margin: 1em 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Fredoka One", sans-serif;
  letter-spacing: 0.02em;
}
h2 {
  text-transform: uppercase;
  color: #232323;
  margin: 20px 10px;
}
h3 {
  font-size: 21px;
  margin: 30px 0 1em;
  color: #232323;
}
#kenoWidget {
  width: 100%;
  margin: auto;
  max-width: 660px;
  min-height: 460px;
}
.widgetBody {
  background: #f5f5f5;
  padding: 30px 20px 20px;
}

/******** BUTTONS AND INPUTS ********/
.selectTimes {
  display: flex;
}
.selectRound {
  position: relative;
  width: 30%;
  margin: 10px 10px 20px 0;
}
.selectRound h5 {
  position: absolute;
  top: -20px;
  margin: 0;
  padding: 0;
  cursor: default;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  display: flex !important;
}
.react-datepicker__input-container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: none;
  border-radius: 3px;
  pointer-events: none;
}
.react-datepicker__triangle {
  left: 70px !important;
}
#kenoWidget .button {
  width: 30%;
  height: 30px;
  margin: 20px 0;
  border: 2px solid #2690d8;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-family: Fredoka One, sans-serif;
  color: #2690d8;
}
#kenoWidget .selectTimes .button {
  background: #fff;
  margin: 10px 10px 20px 0;
}
#kenoWidget .button.buttonWhite {
  background: #fff;
  margin: 10px 10px 20px 0;
}
#kenoWidget .button.buttonWhite.active {
  background: #2690d8;
  color: #fff;
}
#kenoWidget .button.winningsButton {
  background: transparent !important;
  margin: 0;
  border-radius: 0 3px 3px 0;
  width: 180px;
}
/******** BUTTONS AND INPUTS - END ********/

/******** RESULTS ********/
.resultRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px;
}
.allBalls {
  max-width: 500px;
  margin-right: 20px;
}
.lottoBall {
  background-image: url("../assets/ball-yellow.png");
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 0 0 8px hsl(0deg 0% 100% / 60%);
  color: #232323;
  font-family: Fredoka One, sans-serif;
  margin: 5px 5px 0 0;
}
.lottoBall.red {
  background-image: url("../assets/ball-red.png");
}
.kingNumber {
  max-width: 85px;
  margin-top: 11px;
}
.kingNumber p {
  margin: 0;
}

.levelTabs {
  display: flex;
}
.levelText {
  margin: 5px 0 15px;
}
.levelTabs ul {
  display: flex;
  list-style: none;
  margin: 0;
}
.levelTabs span {
  padding: 5px;
  margin: 0 5px;
  text-decoration: none;
  color: #2690d8;
  display: block;
  cursor: pointer;
}
.levelTabs span.active {
  text-decoration: underline;
  font-weight: bold;
}
/******** RESULTS - END ********/

/******** WIN TABLES ********/
.winTables table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}
.winTables thead {
  background: #ffd00b;
}
.winTables td,
.winTables th {
  text-align: left;
  padding: 8px;
}
.winTables tr:nth-child(2n) {
  background-color: #ddd;
}
/******** WIN TABLES - END ********/

@media only screen and (max-width: 550px) {
  .selectTimes,
  .resultRow {
    flex-direction: column;
  }
  .selectRound,
  #kenoWidget .button {
    width: 100%;
  }
  #kenoWidget .button.buttonWhite {
    margin: 0 0 10px;
  }
  .allBalls {
    margin: 0 0 10px;
  }
  .kingNumber {
    max-width: unset;
    margin-top: 10px;
  }
  .levelTabs {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .levelText {
    margin: 5px 0 0;
  }
  .levelTabs ul {
    padding: 0;
    margin-left: -5px;
  }
}
